import * as React from "react"

import aboutImg from "../../images/about-img.jpg"

const AboutContent = () => {
  return (
    <>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
           

            <div className="col-lg-7 col-md-12">
              <div className="about-content">
                <div className="about-title">
                  <h2>OM AMAL EXPRESS</h2>
                  <p>
                  Amal Express Ekonomisk Förening är ett svenskt företag som jobbar med penningöverföring. Företagets affärsidé är att erbjuda privatpersoner och företag att mot en viss avgift kunna pengar till andra länder. Utbetalningar sker hos lokalt Amal Express kontor. Målsättningen är att betalningarna skall nå mottagaren samma dag. Avsändaren får bekräftelse på att de pengar som skickats har mottagits med ett sms. Amal Express har ett tillstånd som ett betaltjänstleverantör från finansinspektionen. Amal Express vill ta långsiktigt socialt ansvar för samhället. Vi jobbar aktivt med att bidra till välbefinnande i de samhällen där Amal Express är närvarande är en integrerad del i verksamheten. Amal Express ska skapa värde för ägare och sina intressenter samt bygga relationer som grundas på ömsesidig respekt och ansvarstagande.
                  </p>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutContent
